<template>
  <div id="order-item" @click="toDetail">
    <!--  基本信息  -->
    <div class="basic-info-box">
      <div class="buyer-info">
        <span v-if="orderInfo['pay_type'] === '2'">门店交易订单</span>
        <span class="name">{{orderInfo['contacts']}}</span>
        <span class="address">{{orderInfo['address']}}</span>
      </div>
      <span class="order-status">{{status[orderInfo['order_status']]}}</span>
    </div>
    <!--  订单所含商品  -->
    <div class="goods-list-box">
      <ul class="goods-list">
        <li class="goods-item" v-for="(item,index) in orderInfo['product_order_info'].slice(0,4)" :key="index">
          <img class="goods-cover" v-lazy="item['product_cover']" alt/>
        </li>
        <li v-if="orderInfo['product_order_info'].length > 4"><span>···</span></li>
      </ul>
    </div>
    <!--  联系方式及订单基本信息  -->
    <div class="contact-info-box">
      <a :href="'tel:'+orderInfo['telephone']" v-if="orderInfo['pay_type'] === '1'" @click.stop="">
        <div class="buyer-tel">
          <img class="icon" src="../assets/images/tel.png" alt/>
          <span class="tel">{{orderInfo['telephone']}}</span>
        </div>
      </a>
      <span class="order-info">共{{orderInfo['product_num']}}件 实付<span
        class="price">￥{{orderInfo['total_amount']}}</span></span>
    </div>
    <!--  操作栏  -->
    <div class="action-bar">
      <span class="action-btn">查看详情</span>
      <span class="action-btn action-btn-highlight" v-if="orderInfo['order_status'] === '2'"
            @click.stop="handleOrder">完成配送</span>
      <span class="action-btn action-btn-highlight" v-else-if="orderInfo['order_status'] === '1'"
            @click.stop="handleOrder">开始配送</span>
    </div>
  </div>
</template>

<script>
  import {Dialog, Toast} from "vant";
  import {handleOrder} from "../api/requist";

  export default {
    props: ['orderInfo'],
    name: "orderItem",
    data() {
      return {
        status:
          {
            '1': '待配送',
            '2': '配送中',
            '3': '已完成',
            '7': '已取消'
          }
      }
    },
    methods: {
      // 查看详情
      toDetail: function () {
        let id = this.orderInfo['id'];
        this.$router.push({
          path: "/listDetail",
          query: {
            id
          }
        });
      },
      // 操作订单，开始配送或完成配送
      handleOrder: function () {
        let orderId = this.orderInfo['id'];
        let orderStatus = parseInt(this.orderInfo['order_status']) + 1;
        let _title = orderStatus - 1 === 1 ? '确认开始配送吗' : '确认完成配送吗';
        let _message = orderStatus - 1 === 1 ? '点击确认将通知用户订单开始配送' : '点击确认将通知用户订单配送完成';
        Dialog.confirm({
          title: _title,
          message: _message
        }).then(() => {
          Toast.loading({
            message: "请求中...",
            forbidClick: true,
          });
          let formData = new FormData();
          formData.append("merch_id", localStorage.getItem("merch_id"));
          formData.append("orders_id", orderId);
          formData.append("order_status", orderStatus + '');
          handleOrder(formData).then((res) => {
            Toast.clear();
            // 开始配送
            if (res.code === 200) {
              this.orderInfo['order_status'] = orderStatus + '';
              Toast("操作成功");
              this.$forceUpdate()
            }
          });
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../common/css/theme.scss";

  #order-item {
    padding: 12px 8px 8px 8px;
    background-color: white;
    border-radius: 4px;

    .basic-info-box {
      padding-bottom: 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 12px;
      line-height: 12*1.4px;
      color: #303133;
      border-bottom: 1px solid #F0F2F5;

      .buyer-info {
        .address {
          margin-left: 8px;
        }
      }

      .order-status {
        color: $theme-color;
        font-weight: bold;
      }
    }

    .goods-list-box {
      margin-top: 12px;

      .goods-list {
        font-size: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: scroll;

        .goods-item {
          margin-right: 8px;

          .goods-cover {
            height: 66px;
            width: 66px;
            background-color: white;
          }
        }
      }
    }

    .contact-info-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;
      padding-bottom: 8px;
      border-bottom: 1px solid #F0F2F5;

      .buyer-tel {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #F5F7FA;
        border-radius: 15px;
        padding: 6px 12px;
        font-size: 0;

        .icon {
          height: 12px;
          width: 12px;
        }

        .tel {
          margin-left: 2px;
          color: #303133;
          font-size: 12px;
          line-height: 12*1.4px;
        }
      }

      .order-info {
        flex: 1;
        text-align: right;
        font-size: 12px;
        line-height: 14*1.4px;

        .price {
          color: $theme-color;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }

    .action-bar {
      margin-top: 8px;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;

      .action-btn {
        margin-left: 8px;
        padding: 4px 8px;
        border: 1px solid #606266;
        border-radius: 4px;
        font-size: 12px;
        line-height: 12*1.4px;
      }

      .action-btn-highlight {
        color: $theme-color;
        border: 1px solid $theme-color;
      }
    }
  }
</style>
