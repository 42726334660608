<template>
    <div id="orders">
        <van-tabs class="orders-container" v-model="active" swipeable sticky>
            <van-tab v-for="(item,index) in tabs" :title="item.name" :key="index">
                <van-pull-refresh class="order-list-container" v-model="refreshing" @refresh="onRefresh(index)">
                    <van-list
                        v-if="!isEmpty[index]"
                        v-model="loading"
                        :finished="finished[index]"
                        finished-text="没有更多了"
                        @load="onLoad(index)">
                        <order-item class="order-item-box"
                                    v-for="(orderInfo,orderIndex) in list[index]" :key="orderIndex"
                                    :orderInfo="orderInfo"/>
                    </van-list>
                    <van-empty v-else
                               :image="require(`@images/nothing.png`)"
                               description="暂无相关订单信息"/>
                </van-pull-refresh>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
    import OrderItem from '../components/orderItem'
    import {ordersList} from "../api/requist";
    import {Toast} from "vant";

    export default {
        name: "orders",
        components: {OrderItem},
        comments: {
            'order-item': OrderItem
        },
        data() {
            return {
                tabs: [
                    {name: '待配送', statusId: '1'},
                    {name: '配送中', statusId: '2'},
                    {name: '已完成', statusId: '3'},
                    {name: '已取消', statusId: '7'}
                ],
                active: 0,
                list: [[], [], [], []],
                loading: false,
                finished: [false, false, false, false],
                refreshing: false,
                isEmpty: [false, false, false, false],
                pageSize: 20,
                pageCurrent: [0, 0, 0, 0]
            }
        },
        activated() {
            // 禁用浏览器返回键
            history.pushState(null, null, document.URL);
            window.addEventListener('popstate', this.disableBrowserBack);
        },
        deactivated() {
            // 清除popstate事件 否则会影响到其他页面
            window.removeEventListener("popstate", this.disableBrowserBack, false)
        },
        methods: {
            // 禁止浏览器返回键
            disableBrowserBack() {
                history.pushState(null, null, document.URL);
            },

            // 获取订单列表
            getOrders: function (index) {
                let _pageCurrent = this.pageCurrent[index];
                let _orderStatus = this.tabs[index].statusId;
                // 构造网络请求
                let formData = new FormData();
                formData.append("merch_id", localStorage.getItem("merch_id"));
                formData.append("order_status", _orderStatus);
                formData.append("pageCurrent", _pageCurrent + 1);
                formData.append("pageSize", this.pageSize);
                ordersList(formData).then((res) => {
                    // 加载数据
                    let code = res.code;
                    if (code === 200) {
                        let orders = res.data['OrdersList'];
                        console.log(orders,333)
                        for (let i = 0; i < orders.length; i++) {
                            this.list[index].push(orders[i]);
                        }
                        this.refreshing = false;
                        this.loading = false;
                        if (orders.length < this.pageSize) {
                            this.finished[index] = true;
                        }
                        this.pageCurrent[index]++;
                    } else if (code === 202) {
                        // 当暂无对应类型订单数据时，显示缺省信息
                        this.refreshing = false;
                        this.loading = false;
                        this.finished[index] = true;
                        this.isEmpty[index] = true;
                    } else {
                        return Toast(res.message);
                    }
                });
            },
            onLoad(index) {
                // 获取订单数据
                this.getOrders(index);
            },
            onRefresh(index) {
                // 清空列表数据
                this.finished[index] = false;
                this.list[index] = [];
                this.pageCurrent[index] = 0;
                this.isEmpty[index] = false;
                this.getOrders(index);
            },
        }
    }
</script>

<style lang="scss">
    #orders {
        height: 100%;
        background-color: #F5F7FA;

        .van-tabs__line {
            background-color: #FF6737;
        }

        .orders-container {
            .order-list-container {
                height: calc(100vh - 44px - 54px);
                overflow-y: scroll;

                .order-item-box {
                    margin: 10px 12px 0 12px;
                }

                .van-empty {
                    padding-top: 30%;

                    .van-empty__image {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
</style>
